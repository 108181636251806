import styled from 'styled-components'
import { StyledFormControlLabel } from '../styled-components/MaterialComponents.styled'

export const NewsletterForm = styled.form`
    display: inline-flex;
    flex-direction: column;
    width: 70%;
    div {
        display: inline-block;
    }
    Input {
        margin-bottom: 0.5rem;
        background-color: rgba(255, 255, 255, 0.3);
        border: none;
        color: rgba(255, 255, 255, 1);
    }

    a {
        transition: all 0.3s ease-in-out;
        color: rgba(255, 255, 255, 0.7);
    }
    a:hover {
        color: white;
    }
`

export const NewsletterFormControlLabel = styled(StyledFormControlLabel)`
    &.MuiFormControlLabel-root {
        margin: 0;
    }
    .PrivateSwitchBase-root-1 {
        padding: 9px 9px 9px 0;
    }
`
