import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import theme from '../../utils/theme'
import GlobalStyle from '../style/GlobalStyle'
import Header from './Header'
import Main from '../style/Main'
import CookiesNotification from './CookiesNotification'
import Footer from '../footer/Footer'
import ScrollUpButton from '../ScrollUpButton'

const Layout = ({ children, headerMode }) => {
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Header mode={headerMode} />
            <Main>{children}</Main>
            {headerMode !== 'shop' ? (
                <ScrollUpButton anchorId="#welcome-section" />
            ) : null}
            <CookiesNotification />
            <Footer />
        </ThemeProvider>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
