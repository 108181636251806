import React, { useState, useEffect } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import ScrollUpSvg from '../images/arrow-icon.svg'

import styled from 'styled-components'

const StyledScrollUpButton = styled.button`
    position: fixed;
    bottom: 50px;
    right: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    transition: opacity 0.2s ease-in-out;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    background-color: white;
    transform: rotate(180deg);
    z-index: 99;
    svg {
        display: block;
        height: 20px;
        width: 100%;
        fill: #aeaeaa;
    }
    @media (max-width: ${({ theme }) => theme.media.phone}) {
        height: 60px;
        width: 60px;
        bottom: 20px;
        right: 20px;
    }
`

const ScrollUpButton = ({ anchorId }) => {
    const [isVisible, setIsVisible] = useState(null)

    const toggleScrollButton = () => {
        if (typeof window !== 'undefined') {
            const scrollY = window.scrollY
            if (scrollY >= 1080) {
                return setIsVisible(true)
            } else {
                return setIsVisible(false)
            }
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', toggleScrollButton)
        } else return
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('scroll', toggleScrollButton)
            }
        }
    }, [])

    return (
        <StyledScrollUpButton
            id="button-scroll_header"
            isVisible={isVisible}
            as={AnchorLink}
            to={`/${anchorId}`}
        >
            <ScrollUpSvg
                aria-label="Scroll back to beginning of the page"
                alt="Arrow up"
            />
        </StyledScrollUpButton>
    )
}

export default ScrollUpButton
