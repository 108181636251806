import styled from 'styled-components'

export const TopNavBar = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 100vw;
    height: 90px;
    text-transform: uppercase;

    @media (max-width: 768px) {
        height: 56px;
    }
    h2 {
        display: none;
    }
`
export const MenuWrapper = styled.div`
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 1260px;

    @media (max-width: 960px) {
        max-width: 100%;
        padding: 10px;
    }
`
export const NavBackground = styled.div`
    height: 90px;
    width: 100vw;
    background-color: black;
    position: absolute;
    top: -90px;
`
