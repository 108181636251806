import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`


    html{

        font-size: 10px;
        overflow-x: hidden;

        
    } 
    body{

        min-height: 100vh;
        max-width: 100vw;

        padding: 0;
        margin: 0;

        font-family: 'Futura T OT Light', sans-serif;

        font-size: 1.6rem;
        
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
            
    *, *::before, *::after{
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        text-shadow: rgba(0,0,0,.01) 0 0 1px;
  
    }

    #gatsby-focus-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }
    a{
        text-decoration: none;
        color: white;
    }
    a:hover{
        text-decoration: underline;
    }
    ul{
        padding: 0;
        margin: 0;
    }
    li{
        list-style: none;
    }
    h2, h3{
        padding: 0;
        margin: 0;
        font-weight: normal;
    }
    address{
        font-style: normal;
    }

    
    
    @media (max-width: 768) {
        html{
            font-size: 8px;

        }
    }
    @media (max-width: 500) {
        html{
            font-size: 7px;

        }
    }
`

export default GlobalStyle
