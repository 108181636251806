import React from 'react'
import {
    FooterCatLinks,
    FooterLiSocialsLink,
    StyledFacebookLogo,
    StyledInstagramLogo,
} from './Footer.styled'

const FooterSocials = () => (
    <FooterCatLinks
        as="div"
        style={{
            display: 'inline-flex',
            alignSelf: 'flex-start',
        }}
    >
        <FooterLiSocialsLink
            as="a"
            aria-label="Otwórz nasz fanpage na Facebooku"
            href="https://www.facebook.com/JaglanaCafe"
            target="_blank"
            rel="noreferrer"
        >
            <StyledFacebookLogo id="facebook-logo" aria-hidden="true" />
        </FooterLiSocialsLink>
        <FooterLiSocialsLink
            as="a"
            aria-label="Otwórz nasz profil na Instagramie"
            href="https://www.instagram.com/jaglana_cafe/"
            target="_blank"
            rel="noreferrer"
        >
            <StyledInstagramLogo id="instagram-logo" aria-hidden="true" />
        </FooterLiSocialsLink>
    </FooterCatLinks>
)

export default FooterSocials
