const footerData = {
    address: {
        street: 'Kramarska 1/5',
        city: 'Poznań',
        postcode: '61-765',
        phone: '+48 884 666 906',
        altPhone: '+48 881 666 907',
        email: 'biuro@jaglana.pl',
        emailShop: 'sklep@jaglana.pl',
    },
}

export default footerData
