import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FilledInput from '@material-ui/core/FilledInput'

export const StyledTextField = styled(TextField)`
    label.Mui-focused {
        color: rgba(242, 204, 12, 1);
        color: black;
    }
    .MuiFormLabel-root {
        font-family: 'Futura T OT Light';
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1.4rem;
    }
    .MuiFilledInput-root {
        margin: 2px;
        font-family: 'Futura T OT';
        font-size: 1.6rem;

        &:before {
            width: 0;
        }
    }
    .MuiInput-underline:after {
        border-bottom-color: rgba(242, 204, 12, 1);
    }
    .MuiFilledInput-underline:after {
        border-bottom-color: rgba(242, 204, 12, 1);
    }
    .MuiOutlinedInput-underline:after {
        border-bottom-color: rgba(242, 204, 12, 1);
    }
`
export const StyledFilledInput = styled(FilledInput)`
    label.Mui-focused {
        color: rgba(242, 204, 12, 1);
        color: black;
    }
    &.MuiFormLabel-root {
        font-family: 'Futura T OT Light';
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1.4rem;
    }
    &.MuiFilledInput-root {
        margin: 2px;
        font-family: 'Futura T OT';
        font-size: 1.6rem;

        &:before {
            width: 0;
        }
    }
    &.MuiInput-underline:after {
        border-bottom-color: rgba(242, 204, 12, 1);
    }
    &.MuiFilledInput-underline:after {
        border-bottom-color: rgba(242, 204, 12, 1);
    }
    &.MuiOutlinedInput-underline:after {
        border-bottom-color: rgba(242, 204, 12, 1);
    }
`
export const StyledFormControl = styled(FormControl)`
    &.MuiFormControl-root {
        margin: 2px;
    }
    .MuiFormLabel-root {
        font-family: 'Futura T OT Light';
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 1.4rem;
    }
    label.Mui-focused {
        color: rgba(242, 204, 12, 1);
        color: black;
    }
`
export const StyledInputLabel = styled(InputLabel)`
    /* .MuiInputLabel-root {
        margin: 2px;
        font-family: 'Futura T OT';
        font-size: 1.6rem;
    } */
    &.MuiFormLabel-root.Mui-focused {
        color: ${({ theme }) => theme.colors.dark};
    }
`
export const StyledSelect = styled(Select)`
    .MuiSelect-root {
        font-family: 'Futura T OT';
        font-size: 1.6rem;
    }
    &.MuiFilledInput-underline:before {
        width: 0;
    }
    &.MuiFilledInput-underline:after {
        border-bottom-color: ${({ theme }) => theme.colors.primary};
    }
`
export const StyledMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        font-family: 'Futura T OT Light' !important;
        font-size: 1.4rem;
        letter-spacing: 0.5px;
    }
`

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    /*input {
        width: 100%;
        border: solid 1px lightgrey;
        font-family: 'Futura T OT Light';
        font-size: inherit;
    }*/
    /* select {
        width: 100%;
        border: solid 1px lightgrey;
        font-family: 'Futura T OT Light';
        font-size: inherit;
    } */
`
export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
`
// export const StyledTextField = styled(TextField)`
//     input:focus {
//         border-color: green;
//     }
// `

export const StyledFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root {
        margin: auto 10px auto;
    }
    .MuiTypography-body1 {
        font-family: 'Futura T OT' !important;
        font-size: 1.4rem;
        letter-spacing: 0px;
    }
`
export const StyledCheckbox = styled(Checkbox)`
    &.MuiCheckbox-colorSecondary.Mui-checked {
        color: ${({ theme }) => theme.colors.primary};
    }
    &.MuiIconButton-colorSecondary {
        color: ${({ theme }) => theme.colors.primary};
    }
`

export const StyledCheckboxNewsletter = styled(Checkbox)`
    &.MuiCheckbox-colorSecondary.Mui-checked {
        color: ${({ theme }) => theme.colors.light};
    }
    &.MuiIconButton-colorSecondary {
        color: ${({ theme }) => theme.colors.light};
    }
`
