export const newsletterScript = () => {
    const js = `
      function ml_webform_success_2970975() {
        var $ = ml_jQuery || jQuery;
        $('.ml-subscribe-form-2970975 .ml-block-success').show();
        $('.ml-subscribe-form-2970975 .ml-block-form').hide();
      };
    `
    const script = document.createElement('script')
    const scriptText = document.createTextNode(js)
    script.appendChild(scriptText)
    document.body.appendChild(script)

    const script2 = document.createElement('script')
    script2.src =
        'https://static.mailerlite.com/js/w/webforms.min.js?v3fd38428efea6ca0487b1230e27a86c6'
    document.body.appendChild(script2)
}

export const checkIfUserSubscribed = email => {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-MailerLite-ApiKey': process.env.GATSBY_MAILERLITE_API_KEY,
        },
    }
    const url = `${process.env.GATSBY_MAILERLITE_SUBSCRIBE_URL}/${email}`

    const isEmailSubscribed = fetch(url, options)
        .then(response => response.json())
        .then(data => {
            if (data.error != null && data.error.code === 123) {
                return false
            } else if (!data.error) {
                return true
            } else {
                console.log(
                    'Could not verify if email address was already enlisted.'
                )
                return false
            }
        })
        .catch(error => error)

    return isEmailSubscribed
}
