import styled from 'styled-components'

const Main = styled.main`
    position: relative;
    max-width: 100vw;
    background-color: rgba(250, 250, 250, 1);
    background-color: white;
    min-height: calc(100vh - 90px - 333px);
`
export default Main
