import styled from 'styled-components'
import { lighten, darken } from 'polished'

const Button = styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 1rem;
    padding: 1.5rem 2.5rem 1.5rem;
    border: none;
    font-family: 'Futura T OT';
    font-size: 1.8rem;
    transition: 0.2s all ease-in-out;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

    @media (max-width: 500px) {
        font-size: 1.8rem;
        padding: 1.5rem 2rem 1.5rem;
    }

    @media (max-width: 400px) {
        font-size: 1.6rem;
        padding: 1rem 1.5rem 1rem;
    }

    &:hover {
        background-color: ${({
            transparent,
            menu,
            primary,
            secondary,
            light,
            theme,
        }) =>
            (transparent && 'transparent') ||
            (menu && 'transparent') ||
            (primary && darken(0.05, theme.colors.primary)) ||
            (secondary && lighten(0.1, theme.colors.secondary)) ||
            (light && darken(0.05, theme.colors.light)) ||
            lighten(0.2, theme.colors.dark)};
    }

    color: ${({ transparent, primary, secondary, light, theme, loading }) =>
        (transparent && theme.colors.light) ||
        (primary && theme.colors.dark) ||
        (secondary && theme.colors.light) ||
        (light && theme.colors.dark) ||
        (loading && 'lightgrey') ||
        theme.colors.light};
    background-color: ${({
        transparent,
        primary,
        secondary,
        light,
        theme,
        loading,
    }) =>
        (transparent && 'transparent') ||
        (primary && theme.colors.primary) ||
        (secondary && theme.colors.secondary) ||
        (light && theme.colors.light) ||
        (loading && 'grey') ||
        theme.colors.dark};

    border: ${({ primary, light }) =>
        (primary && 'none') || (light && 'rgba(0,0,0,.4) solid 2px') || 'none'};

    box-shadow: ${({ loading }) =>
        loading && `0px 0px 10px 0px rgba(0,0,0,.5);`};

    ${({ menu }) =>
        menu &&
        `background-color: transparent;
        color: rgba(255,255,255, 1);
        padding: 0 2rem 0;
        text-transform: uppercase;
        font-size: 1.5rem;
        font-family: 'Futura T OT Light';
        margin: 0;
        position: relative;

        &:hover{
            color: rgba(255,255,255, .6);
        }
        `}

    &:active {
        background-color: ${({
            transparent,
            menu,
            primary,
            secondary,
            light,
            theme,
        }) =>
            (transparent && 'transparent') ||
            (menu && 'transparent') ||
            (primary && darken(0.1, theme.colors.primary)) ||
            (secondary && lighten(0.2, theme.colors.secondary)) ||
            (light && darken(0.1, theme.colors.light)) ||
            lighten(0.4, theme.colors.dark)};

        color: ${({ primary, light, theme }) =>
            (primary && theme.colors.dark) ||
            (light && theme.colors.dark) ||
            theme.colors.light};
        outline: none;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    }

    ${({ disabled }) =>
        disabled &&
        `cursor: not-allowed;
        pointer-events: none;
        opacity: .6;
        `};
    @media screen and (-ms-high-contrast: active) {
        border: 2px solid currentcolor;
    }
`

export default Button
