//Theme object containing colors, fonts and @media

const theme = {
    colors: {
        primary: 'rgba(242, 204, 12, 1)', //#f2cc0c
        secondary: 'rgba(100, 100, 100, 1)',
        tertiary: 'rgba(210, 197, 131, 1)',
        light: 'rgba(255, 255, 255, 1)',
        dark: 'rgba(30, 30, 30, 1)',
        grey: 'rgba(153,153,153,1)',
    },
    fonts: {
        regular: 'Futura T OT Light',
        medium: 'Futura T OT Medium',
        bold: 'Futura T OT',
    },
    media: {
        phone: '768px',
        tablet: '',
        desktop: '',
    },
    mixins: {
        flexRow:
            'display: flex; justify-content: center; align-items: center; height: 100%; width: 100%;',
        flexColumn: `display: flex; flex-direction: column; align-items: center; justify-content: center; height: 100%; width: 100%;`,
    },
}

export default theme
