import React from 'react'
import { Link } from 'gatsby'
import NewsletterForm from '../forms/NewsletterForm'
import Branding from '../layout/Branding'
import FooterLinks from './FooterLinks'
import FooterAddress from './FooterAddress'
import FooterSocials from './FooterSocials'
import {
    FooterTitle,
    StyledFooter,
    OuterList,
    UpperFooterWrapper,
    LowerFooterWrapper,
    FooterCatTitle,
    FooterLiCat,
    FooterColumn,
    FooterColumnRow,
} from './Footer.styled'

const date = new Date().getFullYear()

const Footer = () => (
    <StyledFooter>
        <UpperFooterWrapper id="footer-section">
            <FooterTitle>Stopka</FooterTitle>
            <OuterList>
                <FooterColumnRow>
                    <FooterLiCat>
                        <FooterCatTitle>Odwiedź nas</FooterCatTitle>
                        <FooterAddress />
                    </FooterLiCat>
                    <Branding footer={1} />
                </FooterColumnRow>
                <FooterColumn>
                    <FooterLiCat id="footer-cat_info">
                        <FooterCatTitle>Informacje</FooterCatTitle>
                        <FooterLinks />
                    </FooterLiCat>
                </FooterColumn>
                <FooterColumn>
                    <FooterLiCat id="footer-cat_socials">
                        <FooterCatTitle>Social Media</FooterCatTitle>
                        <FooterSocials />
                    </FooterLiCat>
                    <FooterLiCat id="footer-cat_newsletter">
                        <FooterCatTitle>
                            Zapisz się do newslettera
                        </FooterCatTitle>
                        <NewsletterForm />
                    </FooterLiCat>
                </FooterColumn>
            </OuterList>
        </UpperFooterWrapper>
        <LowerFooterWrapper id="footer-copyright-container">
            <p>
                © {date},{` `}
                <Link to="/">Jaglana</Link>
            </p>
        </LowerFooterWrapper>
    </StyledFooter>
)

export default Footer
