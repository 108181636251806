import styled from 'styled-components'

const Input = styled.input`
    display: inline-flex;

    border: 1px solid lightgray;
    font-family: 'Futura T OT';
    font-size: inherit;
    margin: 0 20px 0 0;

    ${({ number }) =>
        number &&
        ` width: 50px;
        height: 50px;
        text-align: center;
        margin: 10px;
        padding: 10px;
        
        @media(max-width: 500px) {
            width: 35px;
            height: 35px;
            margin: 0 10px 0 0;
            padding: 5px;
        }
        `}

    /*delete arrows from the input [number] field for all browsers*/
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
`

export default Input
