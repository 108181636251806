import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Input from '../styled-components/Input'
import MiniButton from '../styled-components/MiniButton'
import { StyledCheckboxNewsletter } from '../styled-components/MaterialComponents.styled'
import {
    NewsletterForm,
    NewsletterFormControlLabel,
} from './NewsletterForm.styled'
import { newsletterScript, checkIfUserSubscribed } from './newsletterFunctions'

const Newsletter = () => {
    const [form, setForm] = useState({
        email: '',
    })
    const [isChecked, setIsChecked] = useState(false)
    const [isUserSubscribed, setIsUserSubscribed] = useState(null)

    const handleOnChange = () => {
        setIsChecked(!isChecked)
    }

    const handleFormChange = e => {
        setForm({
            email: e.target.value,
        })
    }
    const handleCheckSubscribersList = event => {
        if (isUserSubscribed === false) return

        event.preventDefault()

        const email = form.email

        checkIfUserSubscribed(email).then(result => {
            if (result === false) {
                setIsUserSubscribed(false)
                document.getElementById('newsletter-form-submit').click()
            } else if (result === true) {
                setIsUserSubscribed(true)
            } else console.log(result)
        })
    }
    const [isVisible, setIsVisible] = useState(false)

    const toggleScrollButton = () => {
        // Set GoogleMap visible once scrollY reaches 2000px and leave it that way (do not reload)
        if (typeof window !== 'undefined') {
            const scrollY = window.scrollY
            if (scrollY >= 2000) {
                return setIsVisible(true)
            } else return
        }
    }
    useEffect(() => {
        // Set up scroll listener on component mount to track if map should be loaded
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', toggleScrollButton)
        } else return
    }, [])
    useEffect(() => {
        // Unmount the event listener when map is loaded
        if (isVisible === true && typeof window !== 'undefined') {
            window.removeEventListener('scroll', toggleScrollButton)
        } else return
    }, [isVisible])

    useEffect(() => {
        if (isVisible) {
            newsletterScript()
        } else return
    }, [isVisible])

    return isUserSubscribed ? (
        <div>
            <p>Podany adres email jest już na naszej liście.</p>
        </div>
    ) : (
        <div
            id="mlb2-2970975"
            className="ml-subscribe-form ml-subscribe-form-2970975"
        >
            <div className="ml-vertical-align-center">
                <div
                    className="subscribe-form ml-block-success"
                    style={{ display: 'none' }}
                >
                    <div className="form-section">
                        <p>
                            Dziękujemy, zostałeś dodany do listy subskrybentów
                            newslettera Jaglanej!
                        </p>
                    </div>
                </div>
                <NewsletterForm
                    className="ml-block-form"
                    action="https://static.mailerlite.com/webforms/submit/w2a1m9"
                    // data-id="CODE"
                    data-code="w2a1m9"
                    method="POST"
                    target="_blank"
                >
                    <Input
                        id="newsletter-form-input"
                        type="email"
                        name="fields[email]"
                        className="form-control signup-text"
                        placeholder="Adres email"
                        autoComplete="email"
                        spellCheck="false"
                        autoCapitalize="off"
                        autoCorrect="off"
                        placeholder="Twój adres e-mail"
                        required
                        style={{ borderRadius: 0 }}
                        onChange={handleFormChange}
                    />
                    <input type="hidden" name="ml-submit" value="1" />
                    <NewsletterFormControlLabel
                        onChange={handleOnChange}
                        control={
                            <StyledCheckboxNewsletter
                                required
                                name="checkbox"
                            />
                        }
                        label={
                            <>
                                Przeczytałem/-am i akceptuję{' '}
                                <Link to="/polityka-prywatnosci">
                                    Politykę Prywatności*
                                </Link>
                            </>
                        }
                    />
                    <MiniButton
                        className="primary signup-button"
                        id="newsletter-form-submit"
                        type="submit"
                        onClick={handleCheckSubscribersList}
                        aria-label="Zapisz mnie do newsletter'a"
                        style={{
                            color: 'rgba(255,255,255,.7)',
                            fontSize: 16,
                            border: '1px solid grey',
                            margin: '10px 20px 0 0',
                        }}
                    >
                        Zapisz mnie
                    </MiniButton>
                </NewsletterForm>
            </div>
        </div>
    )
}

export default Newsletter
