import styled, { keyframes, css } from 'styled-components'
import Logo from '../../images/jaglana-logo.svg'
import WelcomeLogo from '../../images/jaglana-welcome-logo.svg'

const pulseAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  25%{
    transform: translate(-50%, -50%) scale(1.05, 1.05) ;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
  }
  75%{
    transform: translate(-50%, -50%) scale(1);
  }
  100%{
    transform: translate(-50%, -50%) scale(1);
  }
`

export const BrandingWrapper = styled.div`
animation: ${({ pulse }) =>
    pulse
        ? css`
              ${pulseAnimation} 2s ease-in-out infinite
          `
        : ''};
    ${({ welcome }) =>
        welcome === 1 &&
        `position: absolute;
         height: 30%;
         width: 40%;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
         z-index: 10;

         @media(max-width: 768px){
            height: 50%;
            width: 70%;
         }
        `}
    ${({ top }) =>
        top === 1 &&
        `position: absolute;
         height: 50%;
         left: 50%;
         top: 50%;
         transform: translate(-50%, -50%);
        `}
    ${({ footer }) =>
        footer === 1 &&
        `margin: 38px auto auto;
        text-align: center;
        width: 40%;
        @media (min-width: 550px) and (max-width: 860px) {
            display: none;
        }
        `}
`

export const StyledLogo = styled(Logo)`
    ${({ top }) =>
        top === 1 &&
        `height: 100%;
        `}
    ${({ footer }) =>
        footer === 1 &&
        `width: 60%;
        `}
`
export const StyledWelcomeLogo = styled(WelcomeLogo)`
    height: 100%;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
`
