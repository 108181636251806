import React from 'react'
import { Link } from 'gatsby'
import {
    BrandingWrapper,
    StyledLogo,
    StyledWelcomeLogo,
} from './Branding.styled'

const Branding = ({ top, footer, welcome, pulse }) => {
    return (
        <BrandingWrapper
            aria-label="Wróć do strony głównej"
            top={top}
            footer={footer}
            welcome={welcome}
            pulse={pulse}
            as={Link}
            to="/"
        >
            {welcome ? (
                <StyledWelcomeLogo
                    aria-hidden="true"
                    alt="Restauracja Jaglana"
                />
            ) : (
                <StyledLogo
                    top={top}
                    footer={footer}
                    aria-hidden="true"
                    alt="Restauracja Jaglana"
                />
            )}
        </BrandingWrapper>
    )
}

export default Branding
