import styled from 'styled-components'

export const ResponsiveWidthWrapper = styled.div`
    display: flex;
    justify-content: ${({ justify }) => (justify ? justify : 'center')};
    align-items: ${({ align }) => (align ? align : 'center')};
    margin: ${({ margin }) => margin};
    padding: ${({ padding }) => padding};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    min-height: ${({ minHeight }) => minHeight};

    background-color: ${({ background }) => background};

    box-shadow: ${({ shadow }) =>
        shadow && `0px 0px 30px 0px rgba(0, 0, 0, 0.08);`};

    @media (max-width: ${({ width }) => width}) {
        width: 100% !important;
    }

    @media (max-width: ${({ toColumnWidth }) =>
            toColumnWidth ? toColumnWidth : '768px'}) {
        flex-direction: column;
        height: auto;
        width: 100% !important;
    }
`
export const ColumnWrapper = styled(ResponsiveWidthWrapper)`
    flex-direction: column;
`

export const RowWrapper = styled(ResponsiveWidthWrapper)`
    flex-direction: row;

    @media (max-width: ${({ toColumnWidth }) =>
            toColumnWidth ? toColumnWidth : '768px'}) {
        flex-direction: column;
        height: auto;
        width: 100% !important;
    }
`
