import styled from 'styled-components'

export const HamburgerButton = styled.button`
    display: none;
    display: block;
    border: none;
    cursor: pointer;
    background-color: transparent;
    height: 48px;
    width: 48px;
    margin: 4px;
    padding: 10px;
    position: absolute;
    top: 75px;
    right: 75px;
    transform: translateX(${({ isOpen }) => (isOpen ? '-20vw' : '0')});
    transition: transform 0.25s ease-in-out;
    z-index: 100;

    @media (max-width: ${({ theme }) => theme.media.phone}) {
        right: 18px;
        top: 0;
        transform: translateX(${({ isOpen }) => (isOpen ? '0' : '0')});
    }
`
export const InnerHamburger = styled.div`
    height: 3px;
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 999;
    outline: ${({ isOpen }) => (isOpen ? 'none' : 'black solid 1px')};
    background-color: ${({ isOpen }) => (isOpen ? 'transparent' : 'white')};
    transition: 0.1s background-color ease-in-out;

    &::before {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        background-color: white;
        position: absolute;
        top: -8px;
        left: 0;
        outline: black solid 1px;
        transform: ${({ isOpen }) =>
            isOpen ? 'translateY(8px) rotate(225deg)' : ''};
        transition: 0.25s transform ease-in-out;
    }
    &::after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        background-color: white;
        outline: black solid 1px;
        position: absolute;
        top: 8px;
        left: 0;

        transform: ${({ isOpen }) =>
            isOpen ? 'translateY(-8px) rotate(-225deg)' : ''};
        transition: 0.25s transform ease-in-out;
    }
`
