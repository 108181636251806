import React from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FooterCatLinks, FooterLiLink } from './Footer.styled'

const FooterLinks = () => (
    <FooterCatLinks id="footer-cat-links-list">
        <FooterLiLink id="footer-cat-link">
            <AnchorLink to="#aboutUs-section">O nas</AnchorLink>
        </FooterLiLink>
        <FooterLiLink id="footer-cat-link">
            <Link to="/regulamin">Regulamin</Link>
        </FooterLiLink>
        <FooterLiLink id="footer-cat-link">
            <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
        </FooterLiLink>
        <FooterLiLink id="footer-cat-link">
            <Link to="/polityka-cookies">Cookies</Link>
        </FooterLiLink>
    </FooterCatLinks>
)

export default FooterLinks
