import React from 'react'
import footerData from './footer-data'
import { StyledAddress } from './Footer.styled'

const {
    address: { street, city, postcode, phone, altPhone, email, emailShop },
} = footerData

const FooterAddress = () => (
    <StyledAddress id="footer-address">
        <p id="footer-cat-row">{street}</p>
        <p id="footer-address-postcode">
            {postcode} {city}
        </p>
        <p id="footer-address-email">
            <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p
            id="footer-address-phone-reservations"
            style={{ margin: '0 0 30px 0' }}
        >
            rezerwacje: <a href={`tel:${phone}`}>{phone}</a>
        </p>
        <p id="footer-address-email">
            <a href={`mailto:${emailShop}`}>{emailShop}</a>
        </p>
        <p id="footer-address-phone">
            sklep: <a href={`tel:${altPhone}`}>{altPhone}</a>
        </p>
    </StyledAddress>
)

export default FooterAddress
