import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import {
    StyledCookiesBar,
    NotificationToggleOff,
} from './CookiesNotification.styled'

const CookiesNotification = () => {
    const [isCookiesBarOn, setIsCookiesBarOn] = useState(null)

    const handleToggleCookiesBarOff = () => {
        //insert a local storage key informing that cookies notification was already read and set off
        localStorage.setItem(
            'jaglana-shop-cookies-notification-off',
            JSON.stringify(true)
        )

        //toggle isCookiesOn state false to unmount the component
        setIsCookiesBarOn(false)
    }

    useEffect(() => {
        //searching for a record in a local storage, which informs us if the cookies bar was already hidden by a user
        let wasCookiesBarClosedBefore = localStorage.getItem(
            'jaglana-shop-cookies-notification-off'
        )

        //if there is no record (null) - render the bar, if there is one - do not
        wasCookiesBarClosedBefore === null
            ? setIsCookiesBarOn(true)
            : setIsCookiesBarOn(false)
    }, [])

    return isCookiesBarOn ? (
        <StyledCookiesBar>
            <NotificationToggleOff onClick={handleToggleCookiesBarOff}>
                ✖
            </NotificationToggleOff>
            <span>
                W celu świadczenia usług na najwyższym poziomie stosujemy pliki
                cookies. Korzystanie z naszej witryny oznacza, że będą one
                zamieszczane w Państwa urządzeniu. W każdym momencie można
                dokonać zmiany ustawień Państwa przeglądarki.{' '}
                <Link to="/polityka-cookies">Przeczytaj politykę cookies.</Link>
            </span>
        </StyledCookiesBar>
    ) : (
        ''
    )
}

export default CookiesNotification
