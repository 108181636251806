import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { useStaticQuery, graphql } from 'gatsby'
import useWindowWidth from '../../utils/hooks/useWindowWidth'
import theme from '../../utils/theme'
import FooterSocials from '../footer/FooterSocials'
import {
    MobileMenuWrapper,
    MobileSideMenuButton,
} from './MobileSideMenu.styled'

const MobileSideMenu = ({ isOpen, toggleSideMenu }) => {
    const data = useStaticQuery(graphql`
        query {
            allNavLinksJson {
                nodes {
                    id
                    elementId
                    type
                    name
                    to
                    href
                }
            }
        }
    `)
    const width = useWindowWidth()
    const [isScreenMobile, setIsScreenMobile] = useState(null)
    useEffect(() => {
        width <= parseInt(theme.media.phone)
            ? setIsScreenMobile(true)
            : setIsScreenMobile(false)
    }, [width])
    return (
        <MobileMenuWrapper id="mobile-menu-container" isOpen={isOpen}>
            <ul id="nav-page">
                {data.allNavLinksJson.nodes.map(link => (
                    <li
                        key={link.id}
                        id={`nav_link-${link.elementId}`}
                        onClick={isScreenMobile ? toggleSideMenu : null}
                    >
                        {link.type === 'a' && (
                            <MobileSideMenuButton
                                as="a"
                                href={
                                    link.elementId === 'menu'
                                        ? process.env.GATSBY_MENU_CARD_URL
                                        : link.href
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                {link.name}
                            </MobileSideMenuButton>
                        )}
                        {link.type === 'link' && (
                            <MobileSideMenuButton as={Link} to={link.to}>
                                {link.name}
                            </MobileSideMenuButton>
                        )}
                        {link.type === 'anchor' && (
                            <MobileSideMenuButton as={AnchorLink} to={link.to}>
                                {link.name}
                            </MobileSideMenuButton>
                        )}
                    </li>
                ))}
            </ul>
            <FooterSocials />
        </MobileMenuWrapper>
    )
}

export default MobileSideMenu
