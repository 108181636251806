import styled from 'styled-components'

const MiniButton = styled.button`
    color: black;
    font-family: 'Futura T OT Light';
    letter-spacing: 0.4px;
    background-color: transparent;
    border: none;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 10px;
    margin: 5px;
`
export default MiniButton
