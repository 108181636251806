import styled from 'styled-components'

export const StyledCookiesBar = styled.div`
    z-index: 100;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.light};
    a {
        color: ${({ theme }) => theme.colors.primary};
    }
    span {
        width: 60%;
        margin: 2rem 0 2rem;
        @media (max-width: 1200px) {
            margin: 1rem 0 1rem;
            width: 90%;
        }
    }
`
export const NotificationToggleOff = styled.span`
    display: block;
    height: 5rem;
    text-align: center;
    max-width: 5rem;
    position: relative;
    font-size: 150%;
    left: 0;
    top: 0;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
`
