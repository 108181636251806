import React, { useState } from 'react'
import Hamburger from './Hamburger'
import MobileSideMenu from './MobileSideMenu'
import { TopNavBar, MenuWrapper } from './LandingNav.styled'

const LandingNav = () => {
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
    const toggleSideMenu = () => {
        setIsSideMenuOpen(!isSideMenuOpen)
    }
    return (
        <TopNavBar id="top-navbar">
            <h2>Nawigacja</h2>
            <MenuWrapper id="navbar-inner-wrapper">
                <Hamburger
                    id="hamburger-button"
                    toggleSideMenu={toggleSideMenu}
                    isOpen={isSideMenuOpen}
                />
            </MenuWrapper>
            <MobileSideMenu
                isOpen={isSideMenuOpen}
                toggleSideMenu={toggleSideMenu}
                id="mobile-side-menu-wrapper"
            />
        </TopNavBar>
    )
}

export default LandingNav
