import styled from 'styled-components'
import FacebookLogo from '../../images/facebook-logo.svg'
import InstagramLogo from '../../images/instagram-logo.svg'

export const StyledFacebookLogo = styled(FacebookLogo)`
    transition: all 0.3s ease-in-out;
    // Logo opacity raised when on wrapping link hover
    fill: white;
    opacity: 0.6;
`
export const StyledInstagramLogo = styled(InstagramLogo)`
    transition: all 0.3s ease-in-out;
    // Logo opacity raised when on wrapping link hover
    fill: white;
    opacity: 0.6;
`

export const StyledFooter = styled.footer`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    color: rgba(255, 255, 255, 0.7);
`
export const UpperFooterWrapper = styled.div`
    width: 1260px;
    margin: 3rem auto 3rem;

    @media (max-width: 1260px) {
        width: 100%;
    }
`

export const FooterTitle = styled.h2`
    padding: 0;
    margin: 0;
    visibility: hidden;
    height: 0;
`
export const OuterList = styled.div`
    display: flex;
    margin: 0;
    padding: 0;
    @media (max-width: 550px) {
        flex-direction: column;
    }
`
export const LowerFooterWrapper = styled.div`
    text-align: center;
`

export const FooterUnorderedList = styled.ul``

export const FooterLiCat = styled.li`
    margin-bottom: 2rem;
`

export const FooterLiLink = styled.li`
    margin: 0 0 5px;

    a {
        transition: all 0.3s ease-in-out;
        color: rgba(255, 255, 255, 0.7);
    }
    a:hover {
        color: white;
    }
`

export const FooterLiSocialsLink = styled(FooterLiLink)`
    margin: 0 1rem 0 0;
    padding: 9px 7.5px 9px;
    height: 48px;
    // Raise svg Insta/Fb logo opacity on hover
    &:hover {
        svg {
            opacity: 1;
        }
    }
`

export const FooterCatTitle = styled.h3`
    color: rgba(255, 255, 255, 1);
    font-family: 'Futura T OT';
    margin-top: 0;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-transform: uppercase;
`

export const FooterColumn = styled.div`
    width: 33%;
    padding-left: 4rem;

    border-right: 1px rgba(255, 255, 255, 0.7) solid;
    &:last-of-type {
        border-right: none;
    }

    @media (max-width: 768px) {
        width: 100%;
        border: none;
    }
`

export const FooterColumnRow = styled(FooterColumn)`
    display: flex;
`

export const StyledAddress = styled.address`
    p {
        margin: 0 0 0.5rem;
    }
    p:nth-of-type(2) {
        margin: 0 0 3rem;
    }
    a {
        transition: all 0.3s ease-in-out;
        color: rgba(255, 255, 255, 0.7);
    }
    a:hover {
        color: white;
    }
`

export const FooterCatLinks = styled.ul`
    padding: 0;
`
