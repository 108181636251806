import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../SEO'
import LandingNav from './LandingNav'

const Header = ({ mode }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    return (
        <>
            <SEO title={data.site.siteMetadata.title} lang="pl" />
            <h1 style={{ display: 'none' }}>{data.site.siteMetadata.title}</h1>
            {mode === 'landing' && <LandingNav />}
        </>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
