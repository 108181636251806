import styled from 'styled-components'
import Button from '../styled-components/Button'

export const MobileMenuWrapper = styled.div`
    display: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 5rem;
    background-color: rgba(25, 25, 25, 1);
    height: 100vh;
    width: 30vw;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(${({ isOpen }) => (isOpen ? '70vw' : '100vw')});
    transition: transform 0.25s ease-in-out;

    ul {
        width: 100%;
        margin: 0;
        padding: 0;
        padding: 2rem 0 2rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    li {
        text-align: center;
    }

    @media (max-width: 768px) {
        width: 100vw;
        transform: translateX(${({ isOpen }) => (isOpen ? '0' : '100vw')});
    }
`
export const MobileSideMenuButton = styled(Button)`
    display: inline-flex;
    width: 100%;
    font-size: 1.8rem;
    margin: 0;
    padding: 0.75rem;
    justify-content: flex-start;
    border-radius: 0;
    background-color: transparent;
    font-family: 'Futura T OT';
`
